import { urlUserProfile } from "../lib/urlHelpers"

function UsernameLink({ user, isLinked, withBy, by }) {
  const link = urlUserProfile(user.nickname)
  // TODO: &nbsp; after By
  return(
    <span className="username-link">
      { withBy ? (by ? by : 'By ') : '' }
      { isLinked ?
        <a href={link}>
          {user.name}
        </a> :
        <span className="user-name">{user.name}</span>
      }
    </span>
  )
}

export default UsernameLink
